import type { DrawerSettings } from '@skeletonlabs/skeleton';

const defaultBottomDrawerSettings: DrawerSettings = {
	blur: 'backdrop-blur-sm',
	border: '!border-0 ring-4 ring-base',
	duration: 300,
	height: 'h-fit max-h-[95dvh]',
	position: 'bottom',
	regionBackdrop: 'z-[999]',
	regionDrawer: 'p-0 pb-6 hide-scrollbar',
	rounded: 'rounded-none rounded-tl-3xl rounded-tr-3xl',
	width: 'w-full'
};

const defaultRightDrawerSettings: DrawerSettings = {
	border: 'tablet:border-l tablet:border-base',
	duration: 300,
	position: 'right',
	regionBackdrop: 'z-[999]',
	regionDrawer: 'p-4 !pb-24 tablet:p-8',
	rounded: 'rounded-none',
	width: 'w-screen tablet:w-[40rem]'
};

export const defaultDrawerSettings = {
	bottom: defaultBottomDrawerSettings,
	right: defaultRightDrawerSettings
};
